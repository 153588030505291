import { useState, useEffect } from 'react'
import { useSelector, connect } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'
import {
    Radio,
    FormLabel,
    RadioGroup,
    FormControl,
    FormControlLabel,
} from '@mui/material'

import { i18nAdminUsers } from './i18n'
import { ROLES } from '../../../utils/roles'
import { GlobalState } from '../../../data/store'
import { useTranslate } from '../../../hooks/translate'
import { User } from '../../../data/reducers/usersReducer'
import { EditUserModal } from './EditUserModal/EditUserModal'
import { clientDataGridStruct, companyDataGridStruct } from './tableUtils'
import { deleteUser, getAllUsers, updateProfile } from '../../../data/actions/usersActions'
import { DeviceStrings, useResponsiveString } from '../../../hooks/useResponsiveString'
import { ConfirmActionModal } from '../../../components/ConfirmActionModal/ConfirmActionModal'

import './styles.css'

function UsersSectorComponent(props: any): JSX.Element {
    const { getAllUsers, deleteUser, updateProfile } = props

    const [usersType, setUsersType] = useState(ROLES.CLIENT)

    const [openEditModal, setOpenEditModal] = useState(false)
    const [editModalData, setEditModalData] = useState(null)

    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
    const [deleteUserId, setDeleteUserId] = useState('')

    const t = useTranslate(i18nAdminUsers)

    const { users, loggedIn } = useSelector((state: GlobalState) => state.users)
    const usersByType = users?.filter(x => x.role === usersType)
    const rows = usersByType?.map((user, i) => ({ id: i, ...user })) ?? []

    useEffect(() => {
        getAllUsers()
    }, [getAllUsers])

    const handleOpenEditModal = (rowData: any) => {
        setEditModalData(rowData.row)
        setOpenEditModal(true)
    }

    const handleCloseEditModal = () => {
        setEditModalData(null)
        setOpenEditModal(false)
    }

    const handleSaveEditChanges = async (data: any) => {
        await updateProfile(data)
        getAllUsers()
    }

    const handleChangeUsersType = (ev: any, value: any) => {
        setUsersType(value)
    }

    const handleOpenConfirmDeleteModal = (ev: any, user: User) => {
        ev.stopPropagation()
        setDeleteUserId(user._id)
        setOpenConfirmDeleteModal(true)
    }
    const handleCloseConfirmDeleteModal = () => {
        setDeleteUserId('')
        setOpenConfirmDeleteModal(false)
    }
    const handleConfirmDeleteUser = () => deleteUser({ adminId: loggedIn._id, deleteUserId })

    const dataGridColumns = usersType === ROLES.CLIENT ? clientDataGridStruct : companyDataGridStruct
    const columns = dataGridColumns(t, handleOpenConfirmDeleteModal)

    const strings = {
        mobile: 'top-control-group-mobile',
        smallDesktop: '',
    } as DeviceStrings
    const classNameControl = useResponsiveString(strings)

    return (
        <div>
            <div className='top-controls'>
                <FormControl className={classNameControl}>
                    <FormLabel>{t.types}</FormLabel>
                    <RadioGroup onChange={handleChangeUsersType} value={usersType} row name="row-radio-buttons-group">
                        <FormControlLabel value={ROLES.CLIENT} control={<Radio />} label={t.type1} />
                        <FormControlLabel value={ROLES.BUSINESS_CLIENT} control={<Radio />} label={t.type2} />
                    </RadioGroup>
                </FormControl>
            </div>
            <DataGrid
                rows={rows}
                // @ts-ignore
                columns={columns}
                onRowClick={handleOpenEditModal}
                disableRowSelectionOnClick
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'registerDateEpoch', sort: 'asc' }],
                    },
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                }}
                pageSizeOptions={[25, 50, 100]}
            />
            {
                openEditModal && (
                    <EditUserModal
                        data={editModalData}
                        handleClose={handleCloseEditModal}
                        handleSaveChanges={handleSaveEditChanges}
                    />
                )
            }
            <ConfirmActionModal
                type="delete"
                text={t.confirmDeleteUser}
                isOpen={openConfirmDeleteModal}
                confirmCallback={handleConfirmDeleteUser}
                handleClose={handleCloseConfirmDeleteModal}
            />
        </div>
    )
}

const mapDispatchToProps = { getAllUsers, deleteUser, updateProfile }

export const UsersSector = connect(null, mapDispatchToProps)(UsersSectorComponent)
