export const i18nProfileInfo = {
	yourId: {
		bg: 'Твоят ID номер',
		en: 'Your ID number'
	},
	email: {
		bg: 'Имейл',
		en: 'Email'
	},
	companyName: {
		bg: 'Име на фирма',
		en: 'Company name'
	},
	companyId: {
		bg: 'ЕИК / Булстат',
		en: 'Company ID'
	},
	companyOwner: {
		bg: 'МОЛ',
		en: 'Company owner'
	},
	companyAddress: {
		bg: 'Адрес на фирма',
		en: 'Company address'
	},
	nameWarn: {
		bg: 'По възможност изпишете името на кирилица',
		en: 'If possible, write the name in Cyrillic'
	},
	name: {
		bg: 'Име',
		en: 'Name'
	},
	surname: {
		bg: 'Фамилия',
		en: 'Surname'
	},
	country: {
		bg: 'Държава',
		en: 'Country'
	},
	city: {
		bg: 'Град / Село',
		en: 'City'
	},
	address: {
		bg: 'Адрес',
		en: 'Address'
	},
	defaultEcontOfficeDeliveryAddress: {
		bg: 'Офис на еконт по подразбиране',
		en: 'Default econt office delivery address'
	},
	phone: {
		bg: 'Телефон',
		en: 'Phone number'
	},
	buttonLabel: {
		bg: 'Запази промените',
		en: 'Save changes'
	}
}
