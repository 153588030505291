export const i18nAdminEditUser = {
    title: {
        bg: 'Промяна данни на потребител',
        en: 'Edit user data'
    },
    address: {
        bg: 'Адрес',
        en: 'Address'
    },
    firstName: {
        bg: 'Име',
        en: 'First name'
    },
    lastName: {
        bg: 'Фамилия',
        en: 'Last name'
    },
    country: {
        bg: 'Държава',
        en: 'Country'
    },
    city: {
        bg: 'Град / Село',
        en: 'City'
    },
    phoneNumber: {
        bg: 'Телефон',
        en: 'Phone'
    },
    buttonLabel1: {
        bg: 'Запази',
        en: 'Save'
    },
    buttonLabel2: {
        bg: 'Затвори',
        en: 'Close'
    }
}
