export const i18nStatsTabs = {
    registeredUsers: {
        bg: 'Регистрирани потребители',
        en: 'Registered users'
    },
    ordersCount: {
        bg: 'Брой пратки',
        en: 'Orders count'
    },
    ordersWeights: {
        bg: 'Тегло пратки',
        en: 'Orders weights'
    },
    incomes: {
        bg: 'Приходи',
        en: 'Incomes'
    },
    destinationTotal: {
        bg: 'Местоназначение всички',
        en: 'Destination total'
    },
    destinationOvertime: {
        bg: 'Местоназначение във времето',
        en: 'Destination overtime'
    },
    hourOfOrder: {
        bg: 'Час на регистрация пратка',
        en: 'Hour of order registration'
    }
}