//@ts-ignore
import CanvasJSReact from '@canvasjs/react-charts'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../../../data/store'
import { Order } from '../../../../data/reducers/ordersReducer'
import { PricesSettings } from '../../../../data/reducers/settingsReducer'
import { calculatePackagesTotalWeight, calculateTransportationPrice } from '../../../../utils/functions'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

export function OrdersWeightsGraphic() {
    const { orders } = useSelector((state: GlobalState) => state.orders)
    const { prices } = useSelector((state: GlobalState) => state.settings)

    const ordersData = mapOrders(orders, prices)
    const totalDataPoints = ordersData.map(ordersByMonth => ({
        x: ordersByMonth.date,
        y: ordersByMonth.totalWeights
    }))
    const econtDataPoints = ordersData.map(ordersByMonth => ({
        x: ordersByMonth.date,
        y: ordersByMonth.econtWeights
    }))
    const tuttopostDataPoints = ordersData.map(ordersByMonth => ({
        x: ordersByMonth.date,
        y: ordersByMonth.tuttopostWeights
    }))

    const options = {
        theme: "light2",
        animationEnabled: true,
        title: {
            text: "Orders count"
        },
        data: [
            {
                type: "spline",
                name: "total count",
                showInLegend: true,
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "Total #,##0",
                dataPoints: totalDataPoints
            },
            {
                type: "spline",
                name: "econt",
                showInLegend: true,
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "Econt #,##0kg",
                dataPoints: econtDataPoints
            },
            {
                type: "spline",
                name: "tuttopost",
                showInLegend: true,
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "TuttoPost #,##0kg",
                dataPoints: tuttopostDataPoints
            }
        ]
    }

    return <CanvasJSChart options={options} />
}

function mapOrders(orders: Order[], prices: PricesSettings) {
    const yearAgo = new Date()
    yearAgo.setFullYear(yearAgo.getFullYear() - 1)
    yearAgo.setHours(0, 0, 0, 0)

    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - 1)

    const registeredOrdersByMonth = []
    while (
        yearAgo.getTime() <= currentDate.getTime()
    ) {
        yearAgo.setMonth(yearAgo.getMonth() + 1)
        const fromDateEpoch = yearAgo.getTime()

        const toDate = new Date(yearAgo)
        toDate.setMonth(toDate.getMonth() + 1)
        const toDateEpoch = toDate.getTime()

        const ordersForCurrentMonth = orders.filter(order => (
            order.registerDateEpoch >= fromDateEpoch &&
            order.registerDateEpoch <= toDateEpoch
        ))

        const econtOrders = ordersForCurrentMonth.filter(order => order.deliveryCompany === 'econt')
        const tuttopostOrders = ordersForCurrentMonth.filter(order => order.deliveryCompany === 'tuttopost')

        const totalWeights = mapWeights(ordersForCurrentMonth, prices)
        const econtWeights = mapWeights(econtOrders, prices)
        const tuttopostWeights = mapWeights(tuttopostOrders, prices)

        registeredOrdersByMonth.push({
            date: new Date(yearAgo),
            month: yearAgo.getMonth(),
            orders: ordersForCurrentMonth,
            totalWeights,
            econtWeights,
            tuttopostWeights
        })
    }

    registeredOrdersByMonth.pop()

    return registeredOrdersByMonth
}

function mapWeights(orders: Order[], prices: PricesSettings) {
    const mapped = orders?.map(x => ({
        count: x.packages.length,
        // @ts-ignore
        weight: calculatePackagesTotalWeight(x.packages || []),
        income: calculateTransportationPrice(x.packages || [], prices),
        deliveryCompany: x.deliveryCompany
    }))

    const mappedWeights = mapped.map((x: any) => {
        if (isNaN(x.weight)) {
            return 0
        }

        return +x.weight
    })
    return mappedWeights.reduce((a: any, b: any) => Math.ceil(a + b), 0)
}