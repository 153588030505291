import { Dialog, Typography } from "@mui/material"
import { Formik } from "formik"

import { i18nAdminEditUser } from "./i18n"
import { EditUserModalForm } from "./EditUserModalForm"
import { useTranslate } from "../../../../hooks/translate"
import { validateCyrillicInput } from "../../../../utils/formik/validations"

export function EditUserModal(props: any): JSX.Element {
    const { handleClose, data, handleSaveChanges } = props

    const t = useTranslate(i18nAdminEditUser)

    return (
        <Dialog open={true} onClose={handleClose} scroll="body">
            <div className='modal-container'>
                <Typography variant="h5">{t.title}</Typography>
                <hr />
                {
                    <Formik
                        initialValues={{
                            firstName: data.firstName,
                            lastName: data.lastName,
                            country: data.country,
                            city: data.city,
                            phoneNumber: data.phoneNumber,
                            address: data.address,
                        }}
                        validate={(values) => {
                            return {
                                ...validateCyrillicInput(values, ['firstName', 'lastName'])
                            }
                        }}
                        onSubmit={async (values: any, { setSubmitting }) => {
                            setSubmitting(true)
                            handleClose()
                            handleSaveChanges({ userId: data._id, ...values })
                        }}
                    >
                        {(props) => <EditUserModalForm {...props} handleClose={handleClose} />}
                    </Formik>
                }
            </div>
        </Dialog>
    )
}