import {
    Button,
    Tooltip,
    TextField,
    IconButton,
    Typography,
} from '@mui/material'
import { DeleteForever } from '@mui/icons-material'

import { i18nAdminEditOrder } from './i18n'
import { useTranslate } from '../../../../../hooks/translate'
import { FormikError } from '../../../../../utils/formik/FormikError'
import { ORDER_STATUS } from '../../../../../data/reducers/ordersReducer'
import { DeliveryInformation } from '../../../../../components/DeliveryInformation/DeliveryInformation'

import './styles.css'

export function EditOrderModalForm(props: any) {
    const { values, touched, errors, setFieldValue, handleChange, handleBlur, handleSubmit } = props

    const t = useTranslate(i18nAdminEditOrder)

    const handleAddItem = () => {
        setFieldValue('packages', [...values.packages, { weight: '1', sizes: '20x25x30', processed: false }])
    }

    const handleDeleteItem = (index: number) => {
        const clone = [...values.packages]
        clone.splice(index, 1)
        setFieldValue('packages', clone)
    }

    const handleChangePackageWeight = (index: number, value: any) => {
        const pckg = values.packages[index]
        const updatedPckg = { weight: value, sizes: pckg.sizes, processed: false }
        const clone = [...values.packages]
        clone.splice(index, 1, updatedPckg)

        setFieldValue('packages', clone)
    }

    const handleChangePackageSizes = (index: number, value: string) => {
        const pckg = values.packages[index]
        const updatedPckg = { weight: pckg.weight, sizes: value, processed: false }
        const clone = [...values.packages]
        clone.splice(index, 1, updatedPckg)

        setFieldValue('packages', clone)
    }

    return (
        <form onSubmit={handleSubmit} className="edit-order-form">
            <Typography variant="h5">{t.delivery}</Typography>
            <div className="edit-order-field">
                <TextField
                    fullWidth
                    size="small"
                    margin="dense"
                    name="receiver"
                    label={t.receiver}
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.receiver}
                    onChange={handleChange}
                />
                <FormikError name="receiver" errors={errors} touched={touched} />
            </div>
            <div className="edit-order-field">
                <TextField
                    fullWidth
                    size="small"
                    margin="dense"
                    label={t.phone}
                    variant="outlined"
                    onBlur={handleBlur}
                    name="receiverPhone"
                    onChange={handleChange}
                    value={values.receiverPhone}
                />
                <FormikError name="receiverPhone" errors={errors} touched={touched} />
            </div>
            <br />
            <DeliveryInformation
                fieldClassName='edit-order-field'
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                values={values}
            />
            <br />
            <hr />
            {
                values.status === ORDER_STATUS.REGISTERED &&
                <>
                    <Typography variant="h5">{t.items}</Typography>
                    <div className="edit-order-field">
                        <div className="edit-order-packages-manage">
                            {values.packages.map((pckg: any, i: number) => {
                                return (
                                    <div key={i} className="edit-order-fields-50-50-with-icon">
                                        <div className="edit-order-field-50">
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                name="packages"
                                                placeholder="5"
                                                label={t.weight}
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={pckg.weight}
                                                onChange={(ev: any) => handleChangePackageWeight(i, ev.target.value)}
                                            />
                                        </div>
                                        <div className="edit-order-field-50">
                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="packages"
                                                label={t.sizes}
                                                variant="outlined"
                                                value={pckg.sizes}
                                                onBlur={handleBlur}
                                                placeholder="40x60x100"
                                                onChange={(ev: any) => handleChangePackageSizes(i, ev.target.value)}
                                            />
                                        </div>
                                        <Tooltip
                                            title={t.deleteItem}
                                            style={values.packages.length === 1 ? { visibility: 'hidden' } : {}}
                                        >
                                            <IconButton onClick={() => handleDeleteItem(i)} color="secondary">
                                                <DeleteForever />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )
                            })}
                            <FormikError name="packages" errors={errors} touched={touched} />
                        </div>
                        <div className="edit-order-add-item-wrapper">
                            <Button fullWidth variant="outlined" size="small" color="primary" onClick={handleAddItem}>
                                {t.addItem}
                            </Button>
                        </div>
                        <br />
                    </div>
                </>
            }
        </form>
    )
}
