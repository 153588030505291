import { useState } from "react"
import { useSelector } from "react-redux"
import {
    // Radio,
    Button,
    Dialog,
    TextField,
    // FormLabel,
    // RadioGroup,
    Typography,
    DialogTitle,
    // FormControl,
    Autocomplete,
    DialogActions,
    DialogContent,
    // FormControlLabel,
    DialogContentText,
} from "@mui/material"

import { i18nAdminCreateLabelFromOrder } from "./i18n"
import { GlobalState } from "../../../../../data/store"
import { texts } from "../../../../../utils/i18n/texts"
import { useEcontOffices } from "../../../../../hooks/useEcontOffices"
import { ORDER_STATUS, Order } from "../../../../../data/reducers/ordersReducer"
import { useTranslate, useTranslateSingle, } from "../../../../../hooks/translate"
import { IDefaultEcontDeliveryAddress, __defaultEcontDeliveryAddress } from "../../../../../data/reducers/usersReducer"
import { calculateTransportationPrice, mapPackagesStringSizesToPackagesObjectsSizes } from "../../../../../utils/functions"

import './styles.css'

interface IProps {
    order: Order | null
    handleClose: () => void
    handleOpenEditOrderModal: (order: any) => void
    handleValidate: (senderAddress: IDefaultEcontDeliveryAddress, order: Order) => void
    handleConfirm: (senderAddress: IDefaultEcontDeliveryAddress, order: Order) => void
}

export function CreateLabelFromOrderModal(props: IProps) {
    const { order, handleClose, handleValidate, handleConfirm, handleOpenEditOrderModal } = props

    const { prices } = useSelector((state: GlobalState) => state.settings)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)
    const { econtOfficeSenderAddress } = useSelector((state: GlobalState) => state.settings.defaults)
    const [senderEcontOfficeAddress, setSenderEcontOfficeAddress] = useState(econtOfficeSenderAddress || __defaultEcontDeliveryAddress)
    const econtOffices = useEcontOffices()

    // const [sizesLessThan60CmValue, setSizesLessThan60CmValue] = useState(1) // hardcode to 0 if we should choose between less or more than 60cm manually
    const [sizesLessThan60CmValue] = useState(1) // hardcode to 0 if we should choose between less or more than 60cm manually

    const t = useTranslate(i18nAdminCreateLabelFromOrder)
    const currencyLabel = useTranslateSingle(texts.currency)

    if (!order) {
        return <></>
    }

    const orderDate = order.registerDate.split(' ')[0]
    const invoiceNum = `${order.orderId}/${orderDate}`

    const create = () => {
        if (sizesLessThan60CmValue === 0) {
            alert('Cannot create label without sizes selection')

            return
        }

        const sizeUnder60cm = sizesLessThan60CmValue === 1

        handleClose()
        // @ts-ignore
        handleConfirm(senderEcontOfficeAddress, { sizeUnder60cm, invoiceNum, ...order })
    }

    const validate = () => {
        if (sizesLessThan60CmValue === 0) {
            alert('Cannot create label without sizes selection')

            return
        }

        const sizeUnder60cm = sizesLessThan60CmValue === 1

        // @ts-ignore
        handleValidate(senderEcontOfficeAddress, { sizeUnder60cm, invoiceNum, ...order })
    }

    const isStatusCorrect = order.status === ORDER_STATUS.ARRIVED_BG
    const areAllPackagesProcessed = order.packages.every(x => !!x.processed)

    const shouldDisable = !isStatusCorrect || !areAllPackagesProcessed

    const text = !isStatusCorrect ? t.issueStatusText : areAllPackagesProcessed ? t.sureText : t.issuePackagesText
    const fontWeight = shouldDisable ? '900' : '100'
    const variant = shouldDisable ? 'h5' : 'h6'
    const color = shouldDisable ? 'secondary' : 'primary'

    const handleOpenEditModal = () => {
        handleClose()
        handleOpenEditOrderModal({ row: order })
    }

    const handleChangeSenderEcontOfficeAddress = (e: any, args: any) => setSenderEcontOfficeAddress(args?.names || __defaultEcontDeliveryAddress)
    // const handleChangeSizesRadioButton = (ev: any) => setSizesLessThan60CmValue(+ev.target.value)

    // const noneSelectedRadioClassName = sizesLessThan60CmValue === 0
    //     ? 'create-label-sizes-radio-option none-selected'
    //     : ''
    // const fRadioButtonClassName = sizesLessThan60CmValue === 1
    //     ? 'create-label-sizes-radio-option selected'
    //     : 'create-label-sizes-radio-option non-selected'
    // const sRadioButtonClassName = sizesLessThan60CmValue === 2
    //     ? 'create-label-sizes-radio-option selected'
    //     : 'create-label-sizes-radio-option non-selected'

    // const firstRadioButtonClassName = noneSelectedRadioClassName ? noneSelectedRadioClassName : fRadioButtonClassName
    // const secondRadioButtonClassName = noneSelectedRadioClassName ? noneSelectedRadioClassName : sRadioButtonClassName

    const packages = mapPackagesStringSizesToPackagesObjectsSizes(order.packages)
    const price = calculateTransportationPrice(packages, prices, order.deliveryCompany === 'tuttopost', !!order.unregistered)

    return (
        <Dialog open={true} onClose={handleClose} className='create-label-modal-container'>
            <div className='modal-contents-wrapper'>
                <DialogTitle>
                    <Typography variant='h4'>{t.title}</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <br />
                        <div className='econt-office-autocomplete'>
                            <Autocomplete
                                fullWidth
                                autoHighlight
                                options={econtOffices}
                                // @ts-ignore
                                value={senderEcontOfficeAddress?.[lang] || ''}
                                onChange={handleChangeSenderEcontOfficeAddress}
                                renderInput={(params) => <TextField label={t.senderEcontOfficeAddress} {...params} />}
                            />
                        </div>
                        <br />
                        <Typography color={color} variant={variant} fontWeight={fontWeight}>
                            {text}
                        </Typography>
                        <br />
                        <Typography variant='h6'>
                            {t.receiver}: <strong className='receiver-info'>{order.receiver}</strong>
                        </Typography>
                        <Typography variant='h6'>
                            {t.invoiceNum}: <strong className='receiver-info'>{invoiceNum}</strong>
                        </Typography>
                        <Typography variant='h6'>
                            {t.receiverEcontOfficeAddress}: <strong className='receiver-info'>
                                {
                                    // @ts-ignore
                                    order.econtOfficeDeliveryAddress?.[lang] || 'ERROR - NO ADDRESS'
                                }
                            </strong>
                        </Typography>
                        <Typography variant='h6'>
                            {t.receiverPhone}: <strong className='receiver-info'>{order.receiverPhone}</strong>
                        </Typography>
                        <Typography variant='h6'>
                            {t.requiredPayment}: <strong className='receiver-info'>{price} {currencyLabel}</strong>
                        </Typography>
                        <br />
                        {/* <FormControl>
                            <FormLabel><Typography variant='h6'>{t.sizesLabel}</Typography></FormLabel>
                            <RadioGroup onChange={handleChangeSizesRadioButton} value={sizesLessThan60CmValue} row name="row-radio-buttons-group">
                                <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    className={firstRadioButtonClassName}
                                    label={<Typography variant='h6'>{t.lessThan60}</Typography>}
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Radio />}
                                    className={secondRadioButtonClassName}
                                    label={<Typography variant='h6'>{t.moreThan60}</Typography>}
                                />
                            </RadioGroup>
                        </FormControl> */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        shouldDisable &&
                        <Button onClick={handleOpenEditModal} variant="outlined" color='primary'>
                            {t.openEditModal}
                        </Button>
                    }
                    <Button disabled={shouldDisable || sizesLessThan60CmValue === 0} onClick={validate} variant="outlined" color='primary'>
                        {t.validate}
                    </Button>
                    <Button disabled={shouldDisable || sizesLessThan60CmValue === 0} onClick={create} variant="contained" color='primary'>
                        {t.confirm}
                    </Button>
                    <Button onClick={handleClose} autoFocus variant="contained" color="info">
                        {t.close}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    )
}
