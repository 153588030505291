import { connect, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { Fade } from "react-awesome-reveal"
import { Typography } from "@mui/material"
import { Formik } from "formik"

import { GlobalState } from "../../data/store"
import { i18nVerifyRegistration } from "./i18n"
import { easeTimes } from "../../utils/easeTimes"
import { useTranslate } from "../../hooks/translate"
import { validateNonEmpty } from "../../utils/formik/validations"
import { VerifyRegistrationForm } from "./VerifyRegistrationForm"
import { DeviceStrings, useResponsiveString } from "../../hooks/useResponsiveString"
import { resendVerificationCode, verifyRegistration, sendVerificationSupportRequest } from "../../data/actions/usersActions"

import './styles.css'

function VerifyRegistrationPageComponent(props: any): JSX.Element {
    const navigate = useNavigate()

    const email = useSelector((state: GlobalState) => state.users.pendingVerificationEmail)

    const classNames = {
        mobile: 'verify-registration-container-mobile',
        smallDesktop: 'verify-registration-container',
    } as DeviceStrings

    const containerClassName = useResponsiveString(classNames)

    const t = useTranslate(i18nVerifyRegistration)

    function requestNewCode(values: any, setFieldValue: (fieldName: string, value: any) => void): void {
        const times = values.newCodeRequestedTimes
        setFieldValue('newCodeRequestedTimes', +times + 1)
        props.resendVerificationCode({ email })
    }

    function requestSupportCheck(setFieldValue: (fieldName: string, value: any) => void): void {
        setFieldValue('supportCheckRequested', true)
        props.sendVerificationSupportRequest({ email })
    }

    return (
        <div className={containerClassName}>
            <div className="verify-registration-form">
                <Fade cascade triggerOnce damping={0.3} direction="up" duration={easeTimes.slow}>
                    <div>
                        <Typography variant="h4" className="header">
                            {t.title}
                        </Typography>
                        <Typography>{t.subTitle}</Typography>
                    </div>
                    <Formik
                        initialValues={{
                            code: '',
                            firstName: '',
                            lastName: '',
                            phoneNumber: '',
                            newCodeRequestedTimes: 0,
                            supportCheckRequested: false,
                        }}
                        validate={(values: any) => {
                            return {
                                ...validateNonEmpty(values, ['code', 'firstName', 'lastName', 'phoneNumber'])
                            }
                        }}
                        onSubmit={async (values: any, { setSubmitting, resetForm }) => {
                            setSubmitting(true)
                            const response = await props.verifyRegistration(values)
                            resetForm()

                            if (response.payload) {
                                navigate('/login')
                            }
                        }}
                    >
                        {(props: any) => (
                            <VerifyRegistrationForm
                                {...props}
                                requestNewCode={requestNewCode}
                                requestSupportCheck={requestSupportCheck}
                            />
                        )}
                    </Formik>
                </Fade>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    verifyRegistration,
    resendVerificationCode,
    sendVerificationSupportRequest
}

export const VerifyRegistrationPage = connect(null, mapDispatchToProps)(VerifyRegistrationPageComponent)
