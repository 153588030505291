import { Button, TextField } from "@mui/material"

import { i18nAdminEditUser } from "./i18n"
import { useTranslate } from "../../../../hooks/translate"
import { FormikError } from "../../../../utils/formik/FormikError"

import './styles.css'

export function EditUserModalForm(props: any) {
    const { values, touched, errors, handleChange, handleBlur, handleSubmit, handleClose } = props

    const t = useTranslate(i18nAdminEditUser)

    return (
        <form onSubmit={handleSubmit} className="edit-user-form">
            <div className="edit-user-field">
                <TextField
                    fullWidth
                    size="small"
                    name="firstName"
                    variant="outlined"
                    onBlur={handleBlur}
                    label={t.firstName}
                    onChange={handleChange}
                    value={values.firstName}
                />
                <FormikError name="firstName" errors={errors} touched={touched} />
            </div>
            <div className="edit-user-field">
                <TextField
                    fullWidth
                    size="small"
                    name="lastName"
                    variant="outlined"
                    label={t.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                />
                <FormikError name="lastName" errors={errors} touched={touched} />
            </div>
            <div className="edit-user-field">
                <TextField
                    fullWidth
                    size="small"
                    name="country"
                    label={t.country}
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.country}
                />
                <FormikError name="country" errors={errors} touched={touched} />
            </div>
            <div className="edit-user-field">
                <TextField
                    fullWidth
                    name="city"
                    size="small"
                    label={t.city}
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.city}
                    onChange={handleChange}
                />
                <FormikError name="city" errors={errors} touched={touched} />
            </div>
            <div className="edit-user-field">
                <TextField
                    fullWidth
                    size="small"
                    name="phoneNumber"
                    variant="outlined"
                    onBlur={handleBlur}
                    label={t.phoneNumber}
                    onChange={handleChange}
                    value={values.phoneNumber}
                />
                <FormikError name="phoneNumber" errors={errors} touched={touched} />
            </div>
            <div className="edit-user-field">
                <TextField
                    fullWidth
                    size="small"
                    name="address"
                    label={t.address}
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.address}
                    onChange={handleChange}
                />
                <FormikError name="address" errors={errors} touched={touched} />
            </div>
            <div className="action-buttons">
                <Button onClick={handleSubmit} variant="contained" color="secondary">
                    {t.buttonLabel1}
                </Button>
                <Button onClick={handleClose} variant="contained" color="info">
                    {t.buttonLabel2}
                </Button>
            </div>
        </form>
    )
}