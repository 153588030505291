export const i18nAdminEditOrder = {
	title: {
		bg: 'Данни за пратка на',
		en: 'Details for package of '
	},
	count: {
		bg: 'Брой пакети',
		en: 'Items count'
	},
	receiver: {
		bg: 'Получател',
		en: 'Receiver'
	},
	totalWeight: {
		bg: 'Общо тегло',
		en: 'Total weight'
	},
	origin: {
		bg: 'Поръчано от',
		en: 'Ordered from'
	},
	valueEur: {
		bg: 'Стойност в EUR',
		en: 'Value EUR'
	},
	description: {
		bg: 'Описание',
		en: 'Description'
	},
	reference: {
		bg: 'Референтен номер или фактура',
		en: 'Reference number of invoice'
	},
	transactionDate: {
		bg: 'Дата на транзакцията',
		en: 'Transaction date'
	},
	itDeliveryCompany: {
		bg: 'Доставчик Италия',
		en: 'Italian Delivery Company'
	},
	trackingNumber: {
		bg: 'Номер за проследяване',
		en: 'Tracking number'
	},
	address: {
		bg: 'Адрес за доставка',
		en: 'Delivery address'
	},
	phone: {
		bg: 'Телефон на получателя',
		en: 'Receiver phone'
	},
	email: {
		bg: 'Имейл на получателя',
		en: 'Receiver email'
	},
	deliveryCompany: {
		bg: 'Доставчик',
		en: 'Delivery company'
	},
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	confirmUncombineText: {
		bg: 'Сигурни ли сте, че искате да декомбинирате тази пратка?',
		en: 'Are you sure you want to uncombine this package?'
	},
	buttonLabel0: {
		bg: 'Декомбинирай',
		en: 'Uncombine'
	},
	buttonLabel1: {
		bg: 'Запази',
		en: 'Save'
	},
	buttonLabel2: {
		bg: 'Затвори',
		en: 'Close'
	},
	deliveryTo: {
		bg: 'Доставка до',
		en: 'Delivery to'
	},
	deliveryCompanyAddressType: {
		bg: 'Доставка до',
		en: 'Delivery to'
	},
	deliveryCompanyAddressTypeOffice: {
		bg: 'Офис',
		en: 'Office'
	},
	deliveryCompanyAddressTypeAddress: {
		bg: 'Адрес',
		en: 'Address'
	}
}
