import { Paper } from "@mui/material"

import { getOrderStatusChip } from "../../../../components/Shared"
import { ORDER_STATUS } from "../../../../data/reducers/ordersReducer"
import { DeviceComponents, useResponsive } from "../../../../hooks/useResponsive"

interface IProps {
    lang: string
    value: string
    setValue(value: string): void
}

export function QuickStatusFilter(props: IProps) {
    const { lang, value, setValue } = props

    const orderStatuses = Object.values(ORDER_STATUS).slice(0, 4)

    const quickStatusFiltersComponents = {
        mobile: (
            <Paper elevation={5} style={{
                padding: '10px 20px',
                width: '200px',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                margin: '10px 0 20px 0',
                justifyContent: 'space-around',
            }}>
                {orderStatuses.map(x => {
                    return (
                        <div style={{
                            width: '100%',
                            margin: '5px',
                            display: 'flex',
                            cursor: 'pointer',
                            transition: '0.5s',
                            // border: '1px solid red',
                            paddingLeft: `${value === x ? '30px' : '0'}`,
                        }} onClick={() => setValue(x)}>
                            {getOrderStatusChip(x, lang)}
                        </div>
                    )
                })}
            </Paper>
        ),
        smallDesktop: (
            <Paper elevation={5} style={{
                height: '35px',
                width: '500px',
                display: 'flex',
                alignItems: 'center',
                margin: '10px 0 20px 0',
                justifyContent: 'space-around',
            }}>
                {orderStatuses.map(x => {
                    return (
                        <div style={{
                            cursor: 'pointer',
                            transition: '0.3s',
                            paddingTop: `${value === x ? '5px' : '0'}`,
                        }} onClick={() => setValue(x)}>
                            {getOrderStatusChip(x, lang)}
                        </div>
                    )
                })}
            </Paper>
        )
    } as DeviceComponents

    return useResponsive(quickStatusFiltersComponents)
}