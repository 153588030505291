import { Formik } from 'formik'
import { useSelector } from 'react-redux'
import { Dialog, Typography } from '@mui/material'

import { i18nProfileEditOrder } from './i18n'
import { GlobalState } from '../../../../../data/store'
import { EditOrderModalForm } from './EditOrderModalForm'
import { useTranslate } from '../../../../../hooks/translate'
import { ORDER_STATUS } from '../../../../../data/reducers/ordersReducer'
import { calculatePackagesTotalWeight } from '../../../../../utils/functions'
import { __defaultEcontDeliveryAddress } from '../../../../../data/reducers/usersReducer'
import { validateMaxLength, validateNonEmpty, validatePackageItems } from '../../../../../utils/formik/validations'

import './styles.css'

export function EditOrderModal(props: any): JSX.Element {
    const { handleClose, data, handleSaveChanges } = props

    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)
    const t = useTranslate(i18nProfileEditOrder)

    const kg = lang === 'bg' ? 'кг.' : 'kg.'
    const totalWeight = calculatePackagesTotalWeight(data?.packages || [])

    return (
        <Dialog open={true} onClose={handleClose} scroll="body">
            <div className="modal-container">
                <Typography variant="h5">
                    {t.title} <strong>{data.receiver}</strong>
                </Typography>
                <hr />
                <Typography>
                    {t.totalWeight}: <strong>{totalWeight} {kg}</strong>
                </Typography>
                <Typography>
                    {t.origin}: <strong>{data.siteOrigin}</strong>
                </Typography>
                <Typography>
                    {t.valueEur}: <strong>{data.valueEur}</strong>
                </Typography>
                <Typography>
                    {t.description}: <strong>{data.description}</strong>
                </Typography>
                <Typography>
                    {t.reference}: <strong>{data.referenceNumberOrInvoice}</strong>
                </Typography>
                <Typography>
                    {t.transactionDate}: <strong>{data.transactionDate}</strong>
                </Typography>
                <hr />
                <Formik
                    initialValues={{
                        // @ts-ignore
                        packages: [...data.packages] || [],
                        receiver: data.receiver || '',
                        itDeliveryCompany: data.itDeliveryCompany || '',
                        trackingNumber: data.trackingNumber || '',
                        receiverPhone: data.receiverPhone || '',
                        deliveryAddress: data.deliveryAddress || '',
                        deliveryCompany: data.deliveryCompany || '',
                        status: data.status || ORDER_STATUS.REGISTERED,
                        deliverTo: data.deliverTo || '',
                        econtOfficeDeliveryAddress: data.econtOfficeDeliveryAddress || __defaultEcontDeliveryAddress
                    }}
                    validate={(values) => {
                        return {
                            ...validateNonEmpty(values, ['receiver', 'receiverPhone', 'deliveryAddress']),
                            ...validateMaxLength(values, 50, ['receiverPhone', 'receiver']),
                            ...validateMaxLength(values, 100, ['deliveryAddress']),
                            ...validatePackageItems(values, 'packages')
                        }
                    }}
                    onSubmit={(values: any, { setSubmitting, resetForm }) => {
                        setSubmitting(true)

                        const { econtOfficeDeliveryAddressFull, ...rest } = values

                        const totalWeight = calculatePackagesTotalWeight(values.packages || [])
                        values.totalWeight = totalWeight

                        handleSaveChanges(data._id, rest)
                        resetForm()
                    }}
                >
                    {(props) => <EditOrderModalForm {...props} handleClose={handleClose} />}
                </Formik>
            </div>
        </Dialog>
    )
}
