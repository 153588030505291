import ReCAPTCHA from 'react-google-recaptcha'
import {
    Button,
    Tooltip,
    Checkbox,
    FormGroup,
    TextField,
    IconButton,
    Typography,
    FormControlLabel,
} from '@mui/material'
import { DeleteForever } from '@mui/icons-material'

import { i18nRegOrder } from '../i18n'
import { texts } from '../../../utils/i18n/texts'
import { useTranslate } from '../../../hooks/translate'
import { FormikError } from '../../../utils/formik/FormikError'
import { AgreeWithTermsAndConditions } from '../../../components/Shared'
import { DeliveryInformation } from '../../../components/DeliveryInformation/DeliveryInformation'

import './styles.css'

export function RegisterOrderFormMobile(props: any) {
    const { values, touched, errors, setFieldValue, handleChange, handleBlur, handleSubmit } = props

    const t = useTranslate(i18nRegOrder)

    const todayDate = new Date()
    const date = todayDate.getDate()
    const month = todayDate.getMonth() + 1
    const year = todayDate.getFullYear()

    const formattedDate = date.toString().length === 1 ? `0${date}` : date
    const formattedMonth = month.toString().length === 1 ? `0${month}` : month

    const datePlaceholder = `${formattedDate}.${formattedMonth}.${year}`

    const handleCaptchaChange = (token: any) => setFieldValue('captchaToken', token)
    const handleCaptchaExpired = () => {
        setFieldValue('captchaToken', '')
    }

    const handleAddItem = () => {
        setFieldValue('packages', [...values.packages, { weight: '1', sizes: '20x25x30', processed: false }])
    }

    const handleDeleteItem = (index: number) => {
        const clone = [...values.packages]
        clone.splice(index, 1)
        setFieldValue('packages', clone)
    }

    const handleChangePackageWeight = (index: number, value: any) => {
        const pckg = values.packages[index]
        pckg.weight = value
        const clone = [...values.packages]
        clone.splice(index, 1, pckg)

        setFieldValue('packages', clone)
    }

    const handleChangePackageSizes = (index: number, value: string) => {
        const pckg = values.packages[index]
        pckg.sizes = value
        const clone = [...values.packages]
        clone.splice(index, 1, pckg)

        setFieldValue('packages', clone)
    }

    return (
        <form onSubmit={handleSubmit} className="register-order-form-mobile">
            <div className="register-order-field">
                <TextField
                    fullWidth
                    size="small"
                    label={t.origin}
                    name="siteOrigin"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.siteOrigin}
                />
                <FormikError name="siteOrigin" errors={errors} touched={touched} />
            </div>
            <div className="register-order-field">
                <TextField
                    fullWidth
                    size="small"
                    name="description"
                    variant="outlined"
                    onBlur={handleBlur}
                    label={t.description}
                    onChange={handleChange}
                    value={values.description}
                />
                <FormikError name="description" errors={errors} touched={touched} />
            </div>
            <div className="register-order-packages-manage">
                {values.packages.map((pckg: any, i: number) => {
                    return (
                        <div key={i} className="register-order-fields-50-50-with-icon">
                            <div className="register-order-field-50">
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="number"
                                    placeholder="5"
                                    name="packages"
                                    label={t.weight}
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={pckg.weight}
                                    onChange={(ev: any) => handleChangePackageWeight(i, ev.target.value)}
                                />
                            </div>
                            <div className="register-order-field-50">
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="packages"
                                    label={t.sizes}
                                    variant="outlined"
                                    value={pckg.sizes}
                                    onBlur={handleBlur}
                                    placeholder="40x60x100"
                                    onChange={(ev: any) => handleChangePackageSizes(i, ev.target.value)}
                                />
                            </div>
                            <Tooltip
                                title={t.deleteItem}
                                style={values.packages.length === 1 ? { visibility: 'hidden' } : {}}
                            >
                                <IconButton onClick={() => handleDeleteItem(i)} color="secondary">
                                    <DeleteForever />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                })}
                <FormikError name="packages" errors={errors} touched={touched} />
                <div className="register-order-add-item-wrapper">
                    <Button fullWidth variant="outlined" size="small" color="primary" onClick={handleAddItem}>
                        {t.addItem}
                    </Button>
                </div>
            </div>
            <div className="register-order-field">
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    label={t.reference}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="referenceNumberOrInvoice"
                    value={values.referenceNumberOrInvoice}
                />
                <FormikError name="referenceNumberOrInvoice" errors={errors} touched={touched} />
            </div>
            <div className="register-order-field">
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    onBlur={handleBlur}
                    label={t.transDate}
                    name="transactionDate"
                    onChange={handleChange}
                    placeholder={datePlaceholder}
                    value={values.transactionDate}
                />
                <FormikError name="transactionDate" errors={errors} touched={touched} />
            </div>
            <div className="register-order-field">
                <TextField
                    fullWidth
                    size="small"
                    name="valueEur"
                    variant="outlined"
                    label={t.valueEur}
                    onBlur={handleBlur}
                    value={values.valueEur}
                    onChange={handleChange}
                />
                <FormikError name="valueEur" errors={errors} touched={touched} />
            </div>
            <div className="register-order-field">
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="itDeliveryCompany"
                    label={t.itDeliveryCompany}
                    value={values.itDeliveryCompany}
                    placeholder="FedEx, TNT, GLS, SDA, Poste Italiane etc."
                />
                <FormikError name="itDeliveryCompany" errors={errors} touched={touched} />
            </div>
            <div className="register-order-field">
                <TextField
                    fullWidth
                    size="small"
                    placeholder="N/A"
                    variant="outlined"
                    onBlur={handleBlur}
                    name="trackingNumber"
                    onChange={handleChange}
                    label={t.trackingNumber}
                    value={values.trackingNumber}
                />
                <FormikError name="trackingNumber" errors={errors} touched={touched} />
            </div>
            <DeliveryInformation
                fieldClassName='register-order-field'
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                values={values}
            />
            <div className="register-order-field">
                <TextField
                    rows={2}
                    fullWidth
                    multiline
                    size="small"
                    name="comment"
                    label={t.comment}
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.comment}
                    onChange={handleChange}
                />
                <FormikError name="comment" errors={errors} touched={touched} />
            </div>
            <div className="register-order-field">
                <TextField
                    fullWidth
                    size="small"
                    type="email"
                    name="email"
                    label={t.email}
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.email}
                    onChange={handleChange}
                />
                <FormikError name="email" errors={errors} touched={touched} />
            </div>
            <div className="register-order-field">
                <Typography>{t.nameWarn}</Typography>
                <TextField
                    fullWidth
                    size="small"
                    label={t.name}
                    name="receiver"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.receiver}
                />
                <FormikError name="receiver" errors={errors} touched={touched} />
            </div>
            <div className="register-order-field">
                <TextField
                    fullWidth
                    size="small"
                    label={t.phone}
                    variant="outlined"
                    name="receiverPhone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.receiverPhone}
                />
                <FormikError name="receiverPhone" errors={errors} touched={touched} />
            </div>
            <div className="register-order-field">
                <FormGroup className="register-checkbox">
                    <FormControlLabel
                        name="agreeWithTC"
                        onChange={handleChange}
                        label={<AgreeWithTermsAndConditions label={t.agreeText} main={t.tcText} />}
                        control={<Checkbox color="primary" checked={values.agreeWithTC} />}
                    />
                </FormGroup>
            </div>

            <div className="register-order-field">
                <ReCAPTCHA
                    onChange={handleCaptchaChange}
                    sitekey={texts.captchaSiteKey}
                    onExpired={handleCaptchaExpired}
                />
            </div>

            <div className="register-order-field">
                <Button
                    size="large"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!values.agreeWithTC}
                >
                    {t.buttonLabel}
                </Button>
            </div>
        </form>
    )
}
